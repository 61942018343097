import React, { useState } from 'react'
import { Label, Form, Button } from 'semantic-ui-react'
import { Formik, Field } from 'formik'
import * as yup from 'yup'

const schema = yup.object().shape({
  currentPassword: yup.string().min(6, 'A senha deve ter pelo menos 6 caracteres.').required('Informe a senha atual.'),
  newPassword: yup.string().min(6, 'A senha deve ter pelo menos 6 caracteres.').required('Informe uma nova senha.'),
  confirmPassword: yup.string().required('Informe uma nova senha.').oneOf([yup.ref('newPassword')], 'Confirmação de senha não coincide com a nova senha informada.')
})

export default function Password({ onChange, federated }) {
  const [editMode, setEditMode] = useState(false)

  const onSubmit = async (values, actions) => {
    if (values.newPassword) {
      if (await onChange(values.currentPassword, values.newPassword))
        setEditMode(false)
    }
    actions.setSubmitting(false)
  }

  return (
    <div>
      <div><Label ribbon>Senha</Label></div>
      <br />
      {federated ?
        <span>Altere a senha no seu provedor de identidade</span>
        : editMode
          ? <Formik initialValues={{ currentPassword: '', newPassword: '', confirmPassword: '' }} validationSchema={schema} onSubmit={onSubmit}>
            {({ isSubmitting, handleReset, handleSubmit }) => (
              <Form loading={isSubmitting} onReset={handleReset} onSubmit={handleSubmit}>
                <Field name='currentPassword' render={({ field, form: { errors, touched } }) => (<Form.Input {...field} fluid placeholder='Senha atual' type='password' required error={touched.currentPassword && errors.currentPassword} />)} />
                <Field name='newPassword' render={({ field, form: { errors, touched } }) => (<Form.Input {...field} fluid placeholder='Nova senha' type='password' required error={touched.newPassword && errors.newPassword} />)} />
                <Field name='confirmPassword' render={({ field, form: { errors, touched } }) => (<Form.Input {...field} fluid placeholder='Confirmar senha' type='password' required error={touched.confirmPassword && errors.confirmPassword} />)} />
                <Button type='submit' fluid primary>Salvar</Button>
              </Form>
            )}
          </Formik>
          : <div>
            <Button onClick={() => setEditMode(true)}>alterar senha</Button>
          </div>}
      <br />
    </div>
  )
}
