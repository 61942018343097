const initialState = {
  message: '',
  form: 'loading'
}

export const global = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE':
      return { ...state, ...action.data }
    default:
      return state
  }
}
