import React, { useRef, useState } from 'react'
import { Image, Modal, Button } from 'semantic-ui-react'
import Cropper from 'react-cropper'
import resizeImage from 'resize-image'

export default function Picture({ src, onSelected }) {
  const pictureRef = useRef(null)
  const cropper = useRef(null)
  const [image, setImage] = useState(null)
  const [showModal, setShowModal] = useState(false)

  const handlePictureClick = () => {
    pictureRef.current.click()
  }

  const handlePictureChange = async (evt) => {
    if (evt.target.files.length) {
      setImage(await new Promise(resolve => {
        const reader = new FileReader()
        reader.onload = e => resolve(e.target.result)
        reader.readAsDataURL(evt.target.files[0])
      }))
      pictureRef.current.value = ''
      setShowModal(true)
    }
  }

  const handleSave = () => {
    onSelected(resizeImage.resize(cropper.current.getCroppedCanvas(), 144, 144, resizeImage.JPEG))
    setShowModal(false)
  }

  return (
    <center>
      <Image circular src={src} width='72' height='72' style={{ cursor: 'pointer' }} onClick={handlePictureClick} />
      <input type='file' accept='image/*' capture='camera' ref={pictureRef} onChange={handlePictureChange} style={{ display: 'none' }} />
      <Modal open={showModal} size='tiny' onClose={() => setShowModal(false)}>
        <Modal.Content>
          <Cropper ref={cropper} src={image} aspectRatio={1} viewMode={2} guides={false} style={{ maxHeight: '50vh' }} />
        </Modal.Content>
        <Modal.Actions>
          <Button primary size='large' onClick={handleSave}>Salvar</Button>
        </Modal.Actions>
      </Modal>
    </center>
  )
}
