import React from 'react'
import { Hub } from '@aws-amplify/core'
import Auth from '@aws-amplify/auth'
import { ApolloContext } from 'react-apollo'
import { Grid, Header, Segment, Loader, Dimmer } from 'semantic-ui-react'
import qs from 'query-string'
import gql from 'graphql-tag'
import Layout from '../components/layout'
import Logo from '../components/logo'
import Picture from '../components/profile/picture'
import Name from '../components/profile/name'
import Email from '../components/profile/email'
import Password from '../components/profile/password'
import 'cropperjs/dist/cropper.css'

const GET_USER = gql`{ user { environmentId credentialId accountId } }`

const GET_DATA = gql`
  query GetData($credentialId:UUID! $accountId:UUID!) {
    credential(id:$credentialId) {
      id
      login
      accountsCredentials(condition:{accountId:$accountId}) {
        nodes {
          account {
            id
            name
            image
            imageUrl
          }
        }
      }
    }
  }
`

const UPDATE_IMAGE = gql`
  mutation UpdateImage($accountId:UUID! $image:Upload!) {
    updateAccount(input:{
      id: $accountId
      patch: { image: $image }
    }) {
      account {
        image
      }
    }
  }
`

const UPDATE_NAME = gql`
  mutation UpdateName($accountId:UUID! $name:String!) {
    updateAccount(input:{
      id: $accountId
      patch: { name: $name }
    }) {
      clientMutationId
    }
  }
`

class Profile extends React.Component {
  constructor(props) {
    super(props)
    this.pictureRef = React.createRef()
    this.state = {
      environmentId: null,
      credentialId: null,
      accountId: null,
      federated: false,
      email: '',
      name: '',
      image: '',
      imageUrl: null,
      loading: false
    }
  }

  componentDidMount() {
    Hub.listen('auth', this.authCallback.bind(this))
    Auth.configure({
      region: 'us-east-1',
      userPoolId: 'us-east-1_G69WJnYmU',
      userPoolWebClientId: '2d5avkpr77pfhb4l5j910ev50l',
      oauth: {
        domain: 'login.mediacaonline.com',
        scope: ['email', 'profile', 'openid'],
        responseType: 'code'
      }
    })
  }

  async authCallback({ payload: { event } }) {
    switch (event) {
      case 'configured': {
        let user
        try {
          const { client } = this.context
          const { data } = await client.query({ query: GET_USER })
          user = data.user

          const auth = await Auth.currentAuthenticatedUser()
          const { data: { credential } } = await client.query({ query: GET_DATA, variables: user })

          this.setState({
            federated: !!auth.attributes.identities,
            credentialId: credential.id,
            email: credential.login,
            accountId: credential.accountsCredentials.nodes[0].account.id,
            name: credential.accountsCredentials.nodes[0].account.name,
            image: credential.accountsCredentials.nodes[0].account.image,
            imageUrl: credential.accountsCredentials.nodes[0].account.imageUrl
          })

        } catch (e) {
          location.href = `/logout/?${qs.stringify({
            env: (user || { environmentId: localStorage.getItem('env') || 1 }).environmentId,
            redirect: location.href,
            method: 'GET'
          })}`
        }
      }
    }
  }

  async pictureSelected(data) {
    if (data) {
      const str = atob(data.split(',')[1])
      let ia = new Uint8Array(str.length)
      for (let i = 0; i < str.length; i++)
        ia[i] = str.charCodeAt(i)
      const blob = new Blob([ia], { type: 'image/jpeg' })

      try {
        this.setState({ loading: true })
        const { client } = this.context
        const { data: { updateAccount: { account: { image } } } } = await client.mutate({
          mutation: UPDATE_IMAGE,
          variables: {
            accountId: this.state.accountId,
            image: blob
          }
        })
        this.setState({ image, imageUrl: data })
      } finally {
        this.setState({ loading: false })
      }
    }
  }

  async handleChangeName(name) {
    const { client } = this.context
    await client.mutate({
      mutation: UPDATE_NAME,
      variables: {
        accountId: this.state.accountId,
        name
      }
    })
    this.setState({ name })
  }

  async handleChangePassword(currentPassword, newPassword) {
    const user = await Auth.currentAuthenticatedUser()
    return await new Promise(resolve => {
      Auth.changePassword(user, currentPassword, newPassword)
        .then(() => {
          alert('Senha alterada com sucesso.')
          resolve(true)
        })
        .catch(err => {
          if (err.code == 'NotAuthorizedException')
            alert('Senha atual inválida.')
          else {
            alert('Ocorreu um erro inesperado.')
            console.log(err)
          }
          resolve(false)
        })
    })
  }

  render() {
    return (
      <Grid textAlign='center' style={{ height: '100vh' }}>
        <Grid.Column style={{ maxWidth: 450, margin: '0 10px' }}>
          <br />
          {this.state.credentialId
            ? <>
              <Header as='h2' textAlign='center'>
                <center>
                  <Logo />
                </center>
              </Header>
              <Segment raised textAlign='left'>
                <Dimmer active={this.state.loading}><Loader /></Dimmer>
                <Picture src={this.state.imageUrl || require('../images/camera.jpg')} onSelected={this.pictureSelected.bind(this)} />
                <Name name={this.state.name} onChange={this.handleChangeName.bind(this)} />
                <Email email={this.state.email} />
                <Password federated={this.state.federated} onChange={this.handleChangePassword.bind(this)} />
              </Segment>
            </>
            : <Loader active={true} />
          }
        </Grid.Column>
      </Grid>
    )
  }
}

Profile.contextType = ApolloContext

export default function Page(props) {
  return (
    <Layout {...props}>
      {() => <Profile />}
    </Layout>
  )
}
