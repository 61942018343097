import React from 'react'
import { Label } from 'semantic-ui-react'

export default function Email({ email }) {
  return (
    <div>
      <div><Label ribbon>E-mail</Label></div>
      <br />
      <div>
        {email}
      </div>
      <br />
    </div>
  )
}
