import React from 'react'
import Helmet from 'react-helmet'
import Auth from '@aws-amplify/auth'
import { Provider } from 'react-redux'
import { ApolloProvider } from 'react-apollo'
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloLink, Observable } from 'apollo-link'
import { createUploadLink } from 'apollo-upload-client'
import fetch from 'isomorphic-unfetch'
import store from '../store'

const request = async (operation) => {
  if (process.browser && localStorage.getItem('token')) {
    operation.setContext({
      headers: {
        authorization: `Bearer ${localStorage.getItem('token')}`
      }
    })
  }
}

const requestLink = new ApolloLink((operation, forward) =>
  new Observable(observer => {
    let handle
    Promise.resolve(operation)
      .then(oper => request(oper))
      .then(() => {
        handle = forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        })
      })
      .catch(observer.error.bind(observer))

    return () => {
      if (handle) handle.unsubscribe()
    }
  })
)

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([
    requestLink,
    createUploadLink({
      uri: process.env.GATSBY_GRAPHQL_URL,
      fetchOptions: { fetch }
    })
  ])
})

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    const url = `${this.props.location.protocol}//${this.props.location.host}/`
    Auth.configure({
      region: 'us-east-1',
      userPoolId: 'us-east-1_G69WJnYmU',
      userPoolWebClientId: '2d5avkpr77pfhb4l5j910ev50l',
      oauth: {
        domain: 'login.mediacaonline.com',
        scope: ['email', 'profile', 'openid'],
        redirectSignIn: url,
        redirectSignOut: url,
        responseType: 'code'
      }
    })
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Conta MOL</title>
          <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
          <link rel="stylesheet" href="//cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/semantic.min.css" />
        </Helmet>

        <ApolloProvider client={client}>
          <Provider store={store}>
            {this.props.children({ location: this.props.location })}
          </Provider>
        </ApolloProvider>

        <style jsx global>{`
          body { background-color: #f6f7fb !important }
        `}</style>
      </>
    )
  }
}
