import React, { useState } from 'react'
import { Label, Icon, Form, Button } from 'semantic-ui-react'
import { Formik, Field } from 'formik'
import * as yup from 'yup'

const schema = yup.object().shape({
  name: yup.string().required()
})

export default function Name({ name, onChange }) {
  const [editMode, setEditMode] = useState(false)

  const onSubmit = async (values, actions) => {
    if (values.name) {
      await onChange(values.name)
      setEditMode(false)
    }
    actions.setSubmitting(false)
  }

  return (
    <div>
      <div><Label ribbon>Nome</Label></div>
      <br />
      {editMode
        ? <Formik initialValues={{ name }} validationSchema={schema} onSubmit={onSubmit}>
          {({ isSubmitting, handleReset, handleSubmit }) => (
            <Form loading={isSubmitting} onReset={handleReset} onSubmit={handleSubmit}>
              <Field name='name' render={({ field, form: { errors, touched } }) => (<Form.Input {...field} fluid required error={!!(touched.name && errors.name)} />)} />
              <Button type='submit' fluid primary>Salvar</Button>
            </Form>
          )}
        </Formik>
        : <div>
          {name}&nbsp;&nbsp;
          <Icon name='edit' color='grey' link onClick={() => setEditMode(true)} />
        </div>}
      <br />
    </div>
  )
}
