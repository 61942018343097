const initialState = {
  username: '',
  password: ''
}

export const user = (state = initialState, action) => {
  switch (action.type) {
    case 'user/UPDATE':
      return { ...state, ...action.user }
    case 'user/RESET':
      return initialState
    default:
      return state
  }
}
